import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { Amplify } from "aws-amplify";
Amplify.configure({
  Auth: {
    userPoolId: "us-east-1_TkhGSOijo",
    region: "us-east-1",
    userPoolWebClientId: "3m2fmtlrpke5vqvdmukd291iqa",
  },
  aws_project_region: "us-east-1",
  aws_appsync_graphqlEndpoint:
    "https://ffbnhgmhbvfvjdr7nmnlvrxlom.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
});
Vue.config.productionTip = false;

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
