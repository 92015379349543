<template>
  <v-app style="background-color: #e6ebf1">
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style>
.FontSize10px {
  font-size: 10px !important;
}
.FontSize12px {
  font-size: 12px !important;
}
.FontSize14px {
  font-size: 14px !important;
}
.FontSize16px {
  font-size: 16px !important;
}
.FontSize18px {
  font-size: 18x !important;
}
.FontSize20px {
  font-size: 20px !important;
}
.FontSize22px {
  font-size: 22px !important;
}
.FontSize24px {
  font-size: 24px !important;
}
.FontSize30px {
  font-size: 30px !important;
}
.FontSize40px {
  font-size: 40px !important;
}
.maxWidthVariant1 {
  max-width: 150px !important;
}
.maxWidthVariant2 {
  max-width: 200px !important;
}
.maxWidthVariant3 {
  max-width: 250px !important;
}
.maxWidthVariant4 {
  max-width: 300px !important;
}
.maxWidthVariant5 {
  max-width: 400px !important;
}
.maxWidthVariant6 {
  max-width: 120px !important;
}
.textField.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.dropdownField.v-select .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.CardVariant1 {
  background-color: #eefaf1 !important;
  border: 1px solid #66d094 !important;
  border-radius: 5px !important;
  color: #0fb756 !important;
  max-width: 70px !important;
}
.CardVariant2 {
  background-color: #f9eeee !important;
  border: 1px solid #f7b6b6 !important;
  border-radius: 4px !important;
  color: #f36f6f !important;
  max-width: 70px !important;
}
.tableVariant th {
  background: #f9f9f9 !important;
  color: #82848f !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}
.tableVariant td {
  font-size: 12px !important;
}

.StickyDialogClass {
  align-self: flex-start !important;
}
.Scrollbar {
  overflow: scroll;
  scrollbar-color: #0c396e #ced8e4;
  scrollbar-width: thin;
}
.hovercardVariant:hover {
  color: #0e407b !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}
.settingsExpand {
  position: absolute !important;
  display: block !important;
  top: 27% !important;
  left: 30% !important;
  transform: translateY(-50%);
  z-index: 1000 !important;
}
.tooltipColorRight.primary::before {
  border-right: solid 8px #246fb3 !important;
}
.tooltipColorRight::before {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -8px;
  border-width: 8px;
  border-style: solid;
  border-top: solid 8px transparent;
  border-bottom: solid 8px transparent;
  border-left: solid 8px transparent;
}
.BorderBlueVariant1 {
  border: 1px solid #316cf4 !important;
  width: 40px !important;
}
.CustomNavigation.v-navigation-drawer {
  overflow: inherit !important;
}
* {
  scrollbar-width: thin !important;
}
.CursorPointer{
  cursor: pointer !important;
}
</style>
