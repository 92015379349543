var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{attrs:{"height":_vm.$vuetify.breakpoint.height,"flat":"","dense":""}},[_c('div',{staticClass:"image-container"},[_c('v-img',{attrs:{"height":_vm.$vuetify.breakpoint.height,"src":require("@/assets/bg3.png")}}),_c('div',{staticClass:"overlay-content"},[_c('v-img',{attrs:{"max-width":"100px","src":require("@/assets/saleswrk_logo.png")}})],1),_c('div',{staticClass:"imgText"},[_c('div',{},[_vm._v("Welcome")]),_c('div',{staticClass:"text-center"},[_vm._v("to")]),_c('div',[_vm._v("SalesWRK")])])],1)])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('Snackbar',{attrs:{"SnackbarComponent":_vm.SnackbarComponent}}),_c('v-card',{attrs:{"height":_vm.$vuetify.breakpoint.height,"flat":"","dense":""}},[_c('v-container',{attrs:{"fluid":"","fill-height":"","height":"100%"}},[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-card',{attrs:{"max-height":"400px","width":"450px","color":"#d4d8d8","flat":""}},[_c('v-row',{},[_c('v-col',{staticClass:"mt-3",attrs:{"align":"center","cols":"12"}},[_c('v-img',{attrs:{"max-width":"150px","src":require("@/assets/logo3.png")}})],1)],1),_c('v-form',{ref:"LoginForm",staticClass:"pt-5 px-3"},[_c('div',{staticClass:"FontSize16px font-weight-regular px-1"},[_vm._v(" Email ID ")]),_c('v-text-field',{staticClass:"FontSize14px font-weight-bold",attrs:{"dense":"","solo":"","flat":"","readonly":_vm.IsOTPFieldEnabled,"filled":_vm.IsOTPFieldEnabled,"rules":[
                    (v) => !!v || 'required',
                    (v) => /.+@.+\..+/.test(v) || 'Must be a valid email',
                  ]},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.ValidateMethod(
                      _vm.IsOTPFieldEnabled ? 'VERIFY_OTP' : 'SEND_OTP'
                    )}},model:{value:(_vm.Login.email_id),callback:function ($$v) {_vm.$set(_vm.Login, "email_id", $$v)},expression:"Login.email_id"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.IsOTPFieldEnabled),expression:"IsOTPFieldEnabled"}],staticClass:"FontSize12px px-3"},[_vm._v(" Please enter the OTP that was sent to your registered email: ")]),_c('v-otp-input',{directives:[{name:"show",rawName:"v-show",value:(_vm.IsOTPFieldEnabled),expression:"IsOTPFieldEnabled"}],ref:"autoFocusOtpField",staticClass:"FontSize12px px-3",attrs:{"length":6,"type":"number","rules":[(v) => !!v || 'required']},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.ValidateMethod(
                    _vm.IsOTPFieldEnabled ? 'VERIFY_OTP' : 'SEND_OTP'
                  )}},model:{value:(_vm.Login.otp),callback:function ($$v) {_vm.$set(_vm.Login, "otp", $$v)},expression:"Login.otp"}}),_c('div',{staticClass:"text-left maxWidthVariant5 px-4"},[(_vm.IsOTPFieldEnabled && _vm.counter === 0)?_c('v-btn',{staticClass:"text-capitalize pa-0 ma-0",attrs:{"small":"","text":"","disabled":_vm.counter !== 0},on:{"click":function($event){return _vm.SignInMethod('SEND_OTP')}}},[_vm._v("Resend OTP")]):_vm._e(),(_vm.IsOTPFieldEnabled == true && _vm.counter !== 0)?_c('span',{staticClass:"grey--text FontSize12px"},[_vm._v("Resend OTP available in "+_vm._s(_vm.counter))]):_vm._e()],1),_c('v-card-actions',{staticClass:"pa-0 py-2 px-3"},[_c('v-btn',{staticClass:"rounded-lg text-capitalize",attrs:{"tile":"","depressed":"","loading":_vm.LoginLoader,"color":"primary"},on:{"click":function($event){return _vm.ValidateMethod(
                      _vm.IsOTPFieldEnabled ? 'VERIFY_OTP' : 'SEND_OTP'
                    )}}},[_vm._v(_vm._s(_vm.IsOTPFieldEnabled ? "Verify OTP" : "Login"))])],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }