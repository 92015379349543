<template>
  <v-app>
    <v-row no-gutters>
      <v-col cols="7">
        <v-card :height="$vuetify.breakpoint.height" flat dense>
          <div class="image-container">
            <v-img
              :height="$vuetify.breakpoint.height"
              src="@/assets/bg3.png"
            />
            <div class="overlay-content">
              <v-img max-width="100px" src="@/assets/saleswrk_logo.png" />
            </div>
            <div class="imgText">
              <div class="">Welcome</div>
              <div class="text-center">to</div>
              <div>SalesWRK</div>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="5">
        <Snackbar :SnackbarComponent="SnackbarComponent" />
        <v-card :height="$vuetify.breakpoint.height" flat dense>
          <v-container fluid fill-height height="100%">
            <v-layout align-center justify-center>
              <v-card max-height="400px" width="450px" color="#d4d8d8" flat>
                <v-row class="">
                  <v-col align="center" cols="12" class="mt-3">
                    <v-img max-width="150px" src="@/assets/logo3.png" />
                  </v-col>
                </v-row>
                <v-form class="pt-5 px-3" ref="LoginForm">
                  <div class="FontSize16px font-weight-regular px-1">
                    Email ID
                  </div>
                  <v-text-field
                    dense
                    class="FontSize14px font-weight-bold"
                    solo
                    flat
                    :readonly="IsOTPFieldEnabled"
                    :filled="IsOTPFieldEnabled"
                    v-model="Login.email_id"
                    :rules="[
                      (v) => !!v || 'required',
                      (v) => /.+@.+\..+/.test(v) || 'Must be a valid email',
                    ]"
                    @keypress.enter.prevent="
                      ValidateMethod(
                        IsOTPFieldEnabled ? 'VERIFY_OTP' : 'SEND_OTP'
                      )
                    "
                  ></v-text-field>
                </v-form>
                <div class="FontSize12px px-3" v-show="IsOTPFieldEnabled">
                  Please enter the OTP that was sent to your registered email:
                </div>
                <v-otp-input
                  v-show="IsOTPFieldEnabled"
                  ref="autoFocusOtpField"
                  :length="6"
                  class="FontSize12px px-3"
                  v-model="Login.otp"
                  type="number"
                  :rules="[(v) => !!v || 'required']"
                  @keypress.enter.prevent="
                    ValidateMethod(
                      IsOTPFieldEnabled ? 'VERIFY_OTP' : 'SEND_OTP'
                    )
                  "
                ></v-otp-input>
                <div class="text-left maxWidthVariant5 px-4">
                  <v-btn
                    v-if="IsOTPFieldEnabled && counter === 0"
                    small
                    text
                    :disabled="counter !== 0"
                    class="text-capitalize pa-0 ma-0"
                    @click="SignInMethod('SEND_OTP')"
                    >Resend OTP</v-btn
                  >
                  <span
                    v-if="IsOTPFieldEnabled == true && counter !== 0"
                    class="grey--text FontSize12px"
                    >Resend OTP available in {{ counter }}</span
                  >
                </div>

                <v-card-actions class="pa-0 py-2 px-3">
                  <v-btn
                    tile
                    depressed
                    :loading="LoginLoader"
                    color="primary"
                    class="rounded-lg text-capitalize"
                    @click="
                      ValidateMethod(
                        IsOTPFieldEnabled ? 'VERIFY_OTP' : 'SEND_OTP'
                      )
                    "
                    >{{ IsOTPFieldEnabled ? "Verify OTP" : "Login" }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-layout>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { Auth } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
import axios from "axios";
export default {
  components: {
    Snackbar,
  },
  data: () => ({
    IsOTPFieldEnabled: false,
    LoginLoader: false,
    IsSessionLoggedOut: false,
    IsEnableAlertBox: false,

    counter: 0,
    interval: null,
    SuccessMessage: "",

    SnackbarComponent: {},
    Login: { email_id: "", otp: "" },
  }),
  mounted() {
    this.disableBrowserRoutingChanges();
    if (sessionStorage.getItem("IsLoggedOut") === "true") {
      this.IsSessionLoggedOut = true;
    } else {
      this.IsSessionLoggedOut = false;
    }
  },
  methods: {
    RouterMethod() {
      location.replace("http://callcentral.io/");
    },
    ValidateMethod(action) {
      if (this.$refs.LoginForm.validate()) {
        this.SignInMethod(action);
      } else {
        this.SnackbarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: `Please enter Email to Login`,
        };
      }
    },
    async SignInMethod(action) {
      switch (action) {
        case "SEND_OTP":
          try {
            this.LoginLoader = true;
            this.user = await Auth.signIn(this.Login.email_id);
            if (this.user.challengeName === "CUSTOM_CHALLENGE") {
              this.SnackbarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "#0e407b",
                SnackbarText: `Verification OTP sent to ${this.Login.email_id}`,
              };

              this.counter = 120;
              this.LoginLoader = false;
              this.$refs.autoFocusOtpField.reset();
              setTimeout(() => {
                this.IsOTPFieldEnabled = true;
                setTimeout(() => {
                  this.$refs.autoFocusOtpField.focus();
                }, 100);
              }, 1000);
              this.$forceUpdate();
              this.startCounter();
            }
          } catch (error) {
            if (
              error.message ===
              "DefineAuthChallenge failed with error Error: NOT_AUTHORIZED : Kindly Sigup."
            ) {
              this.SignInMethod("SIGNUP");
            } else {
              this.LoginLoader = false;
              this.SnackbarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "red",
                SnackbarText: error.message,
              };
            }

            console.log("Err", error.message);
          }
          break;
        case "VERIFY_OTP":
          try {
            this.LoginLoader = true;
            await Auth.sendCustomChallengeAnswer(this.user, this.Login.otp)
              .then(() => {
                this.SnackbarComponent = {
                  SnackbarVmodel: true,
                  SnackbarColor: "#0e407b",
                  SnackbarText: "Login Successfull",
                };
                this.LoginLoader = false;
                this.ActivateMethod();
              })
              .catch((err) => {
                if (
                  err.message ===
                    "VerifyAuthChallengeResponse failed with error Incorrect OTP!!." ||
                  err.message === "Invalid session for the user."
                ) {
                  this.counter = 0;
                  this.Login.otp = "";
                }
                this.SnackbarComponent = {
                  SnackbarVmodel: true,
                  SnackbarColor: "red",
                  SnackbarText: err.message,
                };
                this.OTPErrorMessage = err.message;
                this.LoginLoader = false;
              });
          } catch (error) {
            console.log("err", error);
          }
          break;
        case "SIGNUP":
          try {
            let data = {
              command: "signupOrganization",
              organization_contact_person_email_address: `${this.Login.email_id}`,
            };
            let config = {
              method: "post",
              headers: {
                // "x-api-key": "IjTmpS9QCM8QcrIZZ2pHy64TS6oPdw6A7In2K1M7",
              },
              url: "https://7ivzfrhjih.execute-api.us-east-1.amazonaws.com/prod/organization_signup",
              data: data,
            };
            let result = await axios(config);
            if (result.data.status === "SUCCESS") {
              sessionStorage.setItem("USER_LOGGED_FIRST_TIME", "true");
              this.SignInMethod("SEND_OTP");
            }
          } catch (error) {
            this.SignUpLoader = false;
            this.AgreeSignUpLoader = false;
            console.log("Err", error);
          }
          break;
      }
    },
      disableBrowserRoutingChanges() {
        window.history.pushState(null, "", window.location.href);
        window.onpopstate = function () {
          window.history.pushState(null, "", window.location.href);
        };
      },
    startCounter() {
      this.interval = setInterval(() => {
        if (this.counter > 0) {
          this.counter--;
        } else {
          clearInterval(this.interval);
        }
      }, 1000);
    },
    beforeDestroy() {
      clearInterval(this.interval);
    },
    ActivateMethod() {
      this.IsEnableAlertBox = false;
      setTimeout(() => {
        sessionStorage.setItem("IsLoggedOut", false);
        this.$router.push("LandingPage");
      }, 1000);
    },
    SignInAgainMethod() {
      sessionStorage.setItem("IsLoggedOut", false);
      if (sessionStorage.getItem("IsLoggedOut") === "true") {
        this.IsSessionLoggedOut = true;
      } else {
        this.IsSessionLoggedOut = false;
      }
    },
  },
};
</script>

<style>
.image-container {
  position: relative;
}

.overlay-content {
  position: absolute;
  top: 25%;
  left: 52%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 24px;
}
.imgText {
  position: absolute !important;
  transform: translate(-50%, -50%);
  top: 38% !important;
  left: 52% !important;
  font-family: Comic Sans MS !important;
  font-size: 20px;
}
/*
Comic Sans MS
*/
</style>
