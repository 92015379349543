import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/MainPages/Login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: HomeView,
  },
  {
    path: "/LandingPage",
    name: "LandingPage",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/MainPages/LandingPage.vue"
      ),
    children: [
      {
        path: "/Profile",
        name: "Profile",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/Profile.vue"
          ),
      },
      {
        path: "/Admins",
        name: "Admins",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/Admins.vue"
          ),
      },
      {
        path: "/FormFields",
        name: "FormFields",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/FormFields.vue"
          ),
      },
      {
        path: "/Groups",
        name: "Groups",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/Groups.vue"
          ),
      },
      {
        path: "/MyRepotee",
        name: "MyRepotee",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/MyRepotee.vue"
          ),
      },
      {
        path: "/Products",
        name: "Products",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/Products.vue"
          ),
      },
      {
        path: "/Approvals",
        name: "Approvals",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/Approvals.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
